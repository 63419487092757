.#{$navigation-class-prefix}__icon-nav {
    display: flex;
    align-items: center;
    padding-right: $space-400 - $space-75;
    background-color: $neutral-white;

    @include mq(desktop-down) {
        justify-content: flex-end;

        // need height for enterprise page where icon-nav is empty
        height: var(--navi-height);
    }

    @include mq(tablet-s-up) {
        padding-right: $space-400;
    }

    @include mq(desktop-up) {
        padding-right: 0;
        margin-right: $space-25;
    }

    &::before {
        @include mq(desktop-down) {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
            box-shadow: $shadow-container;
        }
    }
}

.#{$navigation-class-prefix}__icon-nav-item {
    &.#{$navigation-class-prefix}__icon-nav-item--contact,
    &.#{$navigation-class-prefix}__icon-nav-item--burger {
        @include mq(desktop-up) {
            display: none;
        }
    }
}

.#{$navigation-class-prefix}__icon-nav-link {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: $space-75;
    cursor: pointer;

    @media (hover: hover) {
        &:hover {
            .#{$navigation-class-prefix}__icon-nav-icon {
                stroke: $color-base-brand-100;
                color: $color-base-brand-100;
            }

            .#{$navigation-class-prefix}__icon-nav-label {
                color: $color-base-brand-100;
            }
        }
    }

    &:focus-visible {
        .#{$navigation-class-prefix}__icon-nav-icon {
            stroke: $color-base-brand-100;
            color: $color-base-brand-100;
        }

        .#{$navigation-class-prefix}__icon-nav-label {
            color: $color-base-brand-100;
        }
    }

    @include mq(desktop-down) {
        position: relative;
    }

    @include mq(tablet-s-up) {
        padding: $space-100;
    }

    @include mq(desktop-up) {
        padding: $space-100 $space-75 $space-75;
    }
}

.#{$navigation-class-prefix}__icon-nav-label {
    @include mq(desktop-down) {
        @include sr-only;
    }

    @include mq(desktop-up) {
        padding: 0;
        font-size: $font-size-25;
        font-weight: normal;
        line-height: $line-height-100;
        cursor: pointer;
    }
}

.#{$navigation-class-prefix}__icon-nav-icon {
    width: $size-icon-75;
    height: $size-icon-75;
    stroke: $color-base-grey-600;
    color: $color-base-grey-600;

    @include mq(tablet-s-up) {
        width: $size-icon-80;
        height: $size-icon-80;
    }
}
