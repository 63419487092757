.#{$footer-class-prefix}__toggle-button,
.#{$footer-class-prefix}__item-headline {
    position: relative;
    top: auto;
    right: auto;
    display: block;
    width: 100%;
    padding: $space-150 ($space-100 + $size-button-small + $space-25) $space-150 $space-100;
    font-size: $font-size-200;
    color: inherit;
    text-align: left;
}

.#{$footer-class-prefix}__toggle-button {
    cursor: pointer;

    &--active {
        font-weight: $font-weight-bold;

        .#{$footer-class-prefix}__action-icon {
            transform: translate(0, -50%) scaleY(-1) rotate(90deg);
            stroke: $color-accent-white;
            color: $color-accent-white;
        }
    }

    &[aria-expanded='true'] {
        .#{$footer-class-prefix}__action-icon {
            transform: translate(0, -50%) scaleY(-1) rotate(90deg);
        }

        + .#{$footer-class-prefix}__list--level-2 {
            // stylelint-disable-next-line max-nesting-depth
            @include mq(desktop-down) {
                visibility: visible;
                max-height: var(--mc-submenu-height-level-2, none);
            }
        }
    }

    @include mq(desktop-up) {
        display: none;
    }
}

.#{$footer-class-prefix}__item-headline {
    display: none;
    margin: 0;

    @include mq(desktop-up) {
        display: block;
        padding: $space-75 $space-100;
    }
}
