@import '~@vfde-brix/styles/dist/scss/precss/precss';
@import '../../scss/contants';

.mc-content {
    /* stylelint-disable-next-line declaration-no-important */
    margin-top: 0 !important;

    &--spacing-0 {
        padding-top: 0;
    }

    &--spacing-100 {
        padding-top: $space-100;
    }

    &--spacing-200 {
        padding-top: $space-200;
    }

    &--spacing-300 {
        padding-top: $space-300;
    }

    [id] {
        scroll-margin-top: calc(var(--navi-height, 0px) + var(--scroll-margin-top, #{$space-200}));
    }
}

.belt,
.enterprise .belt {
    max-width: $max-page-width;
    margin: 0 auto;
}

.app-mode {
    .#{$header-class-prefix},
    .#{$breadcrumb-class-prefix},
    .#{$footer-class-prefix} {
        // hide header (with navigation inside), breadcrumb and footer in app-mode
        display: none;
    }

    .wrapper {
        padding-top: 0;
    }
}
