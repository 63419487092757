.#{$footer-class-prefix}__social-nav-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 $space-400 calc($space-75 + $space-15) $space-400;
}

.#{$footer-class-prefix}__social-item {
    position: relative;
}

.#{$footer-class-prefix}__social-link {
    position: relative;
    display: block;
    padding: calc($space-75 / 2);

    &:hover {

        .#{$footer-class-prefix}__social-icon {
            stroke: $color-accent-grey-50;
            fill: $color-accent-grey-50;
            color: $color-accent-grey-50;
        }
    }
}

.#{$footer-class-prefix}__social-icon {
    position: relative;
    width: $size-icon-80;
    height: $size-icon-80;
    stroke: $color-accent-white;
    fill: $color-accent-white;
    color: $color-accent-white;
}
