.#{$footer-class-prefix}__list--level-2 {
    position: relative;

    @include mq(desktop-down) {
        visibility: hidden;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height .5s ease-in-out, visibility .5s;
    }
}

.#{$footer-class-prefix}__item--level-2 {
    font-size: $font-size-100;
    font-weight: $font-weight-normal;
    line-height: $line-height-100;

    &:hover {
        .#{$footer-class-prefix}__link--level-2 {
            color: $color-accent-grey-50;
            text-decoration: underline;
        }
    }

    &:last-child {
        @include mq(desktop-down) {
            margin-bottom: $space-150 - $space-25;
        }
    }
}

.#{$footer-class-prefix}__link--level-2 {
    display: block;
    color: $color-accent-white;
    padding: $space-25 $space-100;

    &:visited {
        color: $color-accent-white;
    }
}
