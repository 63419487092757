.#{$subnav-class-prefix} {
    background: $neutral-white;
    padding: $space-75 $space-0;
    border-top: 1px solid $neutral-grey-100;
    border-bottom: 1px solid $neutral-grey-100;
    margin-bottom: $space-200;

    @include mq(desktop-up) {
        border: 0;
        background: none;
        padding: 0;
        margin-bottom: 0;
    }
}

.#{$subnav-class-prefix}__toggle-button {
    display: flex;
    justify-content: space-between;

    // TODO: this is a workaround until the spacing of the sublevel component is fixed
    width: calc(100% - #{$space-50} * 2);
    margin: $space-50 0;
    font-size: $font-size-100;
    line-height: $size-icon-80;
    text-align: left;
    color: $color-base-grey-600;
    cursor: pointer;

    // TABLET VIEWPORT
    @include mq(tablet-s-up) {
        font-size: $font-size-150;
    }

    // NON BURGER VIEWPORT
    @include mq(desktop-up) {
        display: none;
    }

    &:focus,
    &:focus-visible {
        outline: none;
    }

    &:focus {
        @include brix-focus(false, $space-50);
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    @include mq(desktop-down) {
        &[aria-expanded='true'] {
            font-weight: $font-weight-bold;

            .#{$subnav-class-prefix}__action-icon {
                transform: translate(0, 0%) scaleY(-1) scaleX(1) rotate(90deg);
            }

            + .#{$subnav-class-prefix}__list-container {
                visibility: visible;
                max-height: var(--mc-submenu-height, none);
            }
        }
    }
}

// ACTION ICON
.#{$subnav-class-prefix}__action-icon {
    top: 50%;
    left: 50%;
    width: $size-icon-80;
    height: $size-icon-80;
    transform: translate(0, 0%) scaleY(1) scaleX(1) rotate(90deg);
    transition: transform $duration-500 $ease-in-out-sine;
    stroke: $color-base-brand-100;
    color: $color-base-brand-100;

    @include mq(desktop-up) {
        display: inline;
        width: 1.714rem;
        height: 1.714rem;
    }
}

// LIST CONTAINER
.#{$subnav-class-prefix}__list-container {
    @include mq(desktop-down) {
        max-height: 0;
        overflow: hidden;
        visibility: hidden;
        transition: max-height $duration-500 $ease-in-out-sine, visibility $duration-500 $ease-in-out-sine;
    }

    @include mq(desktop-up) {
        display: flex;
    }
}

// LEVEL 1
.#{$subnav-class-prefix}__list--level-1 {
    line-height: normal;
    padding-top: $space-150;
    display: flex;
    flex-flow: column wrap;

    @include mq(desktop-up) {
        padding-top: 0;
        flex-direction: row;
    }
}

// LEVEL 1 ITEM
.#{$subnav-class-prefix}__item--level-1 {
    position: relative;
    margin-bottom: $space-100;
    font-size: $font-size-100;
    text-decoration: underline;

    @include mq(desktop-up) {
        margin: 0 $space-100 0 0;
        font-size: $font-size-200;
        font-weight: $font-weight-bold;
        text-decoration: none;

        [aria-expanded='true'] {
            .#{$subnav-class-prefix}__action-icon {
                transform: translate(0, 0%) scaleY(-1) scaleX(1) rotate(90deg);
            }

            ~ .#{$subnav-class-prefix}__list--level-2 {
                display: block;
            }
        }
    }

    .#{$subnav-class-prefix}__action-icon {
        display: none;
        margin-left: -$space-15;
        stroke: $color-base-grey-600;
        color: $color-base-grey-600;

        @include mq(desktop-up) {
            display: inline;
        }
    }
}

// LEVEL 1 LINK
.#{$subnav-class-prefix}__link--level-1 {
    @include mq(desktop-up) {
        display: block;
        padding: $space-50 0;
    }

    &:focus-visible,
    &[aria-expanded='true'] {
        text-decoration: none;
        color: $color-base-brand-100;

        .#{$subnav-class-prefix}__action-icon {
            @include mq(desktop-up) {
                stroke: $color-base-brand-100;
                color: $color-base-brand-100;
            }
        }

        &::after {
            @include mq(desktop-up) {
                background-color: $color-base-brand-100;
            }
        }
    }

    &:focus {
        @include brix-focus(false, $space-25);

        text-decoration: none;
    }

    &:focus-visible {
        outline: none;
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    &::after {
        $height: 1px;

        content: '';
        position: absolute;
        left: 0;
        top: calc(100% - #{$height});
        width: 100%;
        height: $height;
        background-color: transparent;
    }
}

// LEVEL 2
.#{$subnav-class-prefix}__list--level-2 {
    position: absolute;
    z-index: 2;
    top: 100%;
    left: 0;
    padding: $space-100;
    background: $neutral-white;
    box-shadow: $shadow-container;
    min-width: $font-size-200;
    width: max-content;
    display: none;
    border-radius: $border-radius-default;

    :last-child {
        margin-bottom: 0;
    }
}

// LEVEL 2 ITEM
.#{$subnav-class-prefix}__item--level-2 {
    font-weight: $font-weight-normal;
    font-size: $font-size-100;
    margin-bottom: $space-100;
}

// FOCUS STYLE
.#{$subnav-class-prefix}__link--level-2 {
    line-height: $line-height-100;

    &:hover,
    &:focus-visible {
        color: $color-base-brand-100;
    }

    &:focus {
        @include brix-focus(false, $space-50);
    }

    &:focus-visible {
        outline: none;
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    &:active {
        outline: none;
    }
}
