.#{$navigation-class-prefix}__action-icon {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    width: $size-icon-75;
    height: $size-icon-75;
    stroke: $color-base-grey-600;
    color: $color-base-grey-600;
    transform: translate(-50%, -50%) scaleY(1) rotate(90deg);
    transition: transform $duration-500 $ease-in-out-sine;

    @at-root {
        .#{$navigation-class-prefix}__item--level-1.#{$navigation-class-prefix}__item--no-children &,
        .#{$navigation-class-prefix}__item--level-2.#{$navigation-class-prefix}__item--no-children &,
        .#{$navigation-class-prefix}__item--level-3 &,
        .#{$navigation-class-prefix}__meta-nav-list & {
            left: auto;
            right: $space-100;
            transform: translate(0, -50%) scaleY(1) rotate(0deg);
            pointer-events: none;
        }

        .#{$navigation-class-prefix}__meta-nav-list--right & {
            left: $space-100;
            right: auto;
        }

        .#{$navigation-class-prefix}__meta-nav-list--left & {
            stroke: $color-accent-white;
            color: $color-accent-white;
        }
    }

    @include mq(desktop-up) {
        display: none;
    }
}
