$core-styles-in-project: true;

@import '~@vfde-brix/styles/dist/scss/precss/precss';
@import '../../scss/contants';

.#{$navigation-class-prefix}__meta-nav-item--contact-number {
    max-width: 0;
    max-height: $space-200;
    visibility: hidden;
    opacity: 0;
    overflow: hidden;
    transition: max-width $duration-500 $ease-in-sine, visibility $duration-500 $ease-in-sine, opacity $duration-500 $ease-in-sine;

    > .#{$navigation-class-prefix}__meta-nav-link {
        display: flex;
        justify-content: flex-end;
        background-color: transparent;
        color: $color-accent-white;
        cursor: pointer;

        &:hover {
            @include mq(desktop-up) {
                color: $color-accent-grey-50;
            }
        }
    }
}

.#{$navigation-class-prefix}__meta-nav-item--contact-number-visible {
    max-width: $max-page-width;
    visibility: visible;
    opacity: 1;
}

.#{$navigation-class-prefix}__meta-nav-link-contact-label {
    white-space: nowrap;
}

.#{$navigation-class-prefix}__icon-contact {
    width: $size-icon-75;
    height: $size-icon-75;
    stroke: $color-accent-white;
    color: $color-accent-white;
    margin-left: $space-50;
}

.#{$navigation-class-prefix}__icon-nav-item--contact {
    visibility: hidden;
    opacity: 0;
    transition: visibility $duration-200 $ease-in-sine, opacity $duration-200 $ease-in-sine;
}

.#{$navigation-class-prefix}__icon-nav-item--contact-visible {
    visibility: visible;
    opacity: 1;
}
