$core-styles-in-project: true;

@import '~@vfde-brix/styles/dist/scss/precss/precss';
@import '../../scss/contants';

$basket-link-class-prefix: '#{$navigation-class-prefix}-basket';

.#{$basket-link-class-prefix} {
    @include mq(desktop-up) {
        position: relative;
    }

    &__indicator {
        position: absolute;
        top: 7px;
        right: 4px;
        width: $size-icon-50;
        height: $size-icon-50;
        border-radius: 50%;
        background-color: $color-brand-100;
        font-size: $font-size-25;
        font-weight: $font-weight-bold;
        color: $color-accent-white;

        @include mq(tablet-s-up) {
            top: 12px;
            right: 12px;
        }

        @include mq(desktop-up) {
            right: 16px;
        }
    }

    &__indicator-count {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
    }
}
