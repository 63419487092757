.#{$footer-class-prefix}__legal-nav-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding: 0 $space-200 $space-200 $space-200;
}

.#{$footer-class-prefix}__legal-item {
    position: relative;

    // this was once 12px ($font-size-25) but due to INC-39020 it was changed to 14px ($font-size-50)
    font-size: $font-size-50;
    line-height: $line-height-100;
}

.#{$footer-class-prefix}__copy-text,
.#{$footer-class-prefix}__legal-link {
    position: relative;
    display: block;
    padding: calc($space-75 / 2);
    margin-top: -$space-15;
    margin-bottom: -$space-15;
    color: $color-accent-white;
}

.#{$footer-class-prefix}__legal-link {
    &:visited {
        color: $color-accent-white;
    }

    &:hover {
        color: $color-accent-grey-50;
    }
}
