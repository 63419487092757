.#{$navigation-class-prefix}__meta-nav {
    display: none;
    flex-direction: column;
    position: relative;

    @include mq(desktop-up) {
        position: absolute;
        top: 0;
        left: 0;
        display: flex;
        flex-direction: row;
        align-items: center;
        width: 100%;
        background-color: $color-accent-black;

        // logo padding depending on the viewport
        @include logo-padding;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $half-percent;
        z-index: -1;
        display: none;
        width: 100vw;
        height: 100%;
        background: $color-accent-black;
        transform: translateX(-$half-percent);

        @include mq(desktop-up) {
            display: block;
        }
    }
}

// LIST //

.#{$navigation-class-prefix}__meta-nav-list {
    @include mq(desktop-down) {
        background: $neutral-white;
    }

    @include mq(desktop-up) {
        display: flex;
        flex-direction: row;
    }

    &--left {
        @include mq(desktop-down) {
            margin-bottom: $space-100;
        }

        .#{$navigation-class-prefix}__meta-nav-item {
            @include mq(desktop-down) {
                font-size: $font-size-300;
                line-height: $line-height-50;
                background: $color-accent-grey-500;
            }

            &--selected {
                @include mq(desktop-down) {
                    display: none;
                }
            }
        }

        .#{$navigation-class-prefix}__meta-nav-link {
            @include mq(desktop-down) {
                padding: $space-100;
            }

            &:hover {
                @include mq(desktop-up) {
                    background: $color-accent-white;
                }
            }

            &:focus-visible {
                background: $color-accent-white;

                .#{$navigation-class-prefix}__action-icon {
                    stroke: $color-base-brand-100;
                    color: $color-base-brand-100;
                }
            }

            &:link,
            &:visited {
                @include mq(desktop-down) {
                    color: $color-accent-white;
                }

                &:hover {
                    @include mq(desktop-up) {
                        color: $color-base-brand-100;
                    }
                }

                &:focus-visible {
                    color: $color-base-brand-100;
                }
            }
        }
    }

    &--right {
        @include mq(desktop-down) {
            padding-bottom: $space-200;
        }

        @include mq(desktop-up) {
            margin-left: auto;
        }

        .#{$navigation-class-prefix}__meta-nav-item {
            display: none;

            @include mq(desktop-up) {
                display: block;
            }

            &--contact,
            &--net {
                // 'contact' and 'net' item should also be shown in mobile breakpoint
                display: block;
            }
        }

        .#{$navigation-class-prefix}__meta-nav-link {
            @include mq(desktop-down) {
                text-decoration: underline;
            }

            &:hover,
            &:focus-visible {
                text-decoration: underline;

                @include mq(desktop-down) {
                    color: $color-base-brand-100;
                }

                .#{$navigation-class-prefix}__action-icon {
                    @include mq(desktop-down) {
                        stroke: $color-base-brand-100;
                        color: $color-base-brand-100;
                    }
                }
            }

            &:link,
            &:visited {
                &:hover {
                    @include mq(desktop-up) {
                        color: $color-accent-grey-50;
                    }
                }
            }
        }
    }
}

// ITEM //

.#{$navigation-class-prefix}__meta-nav-item {
    font-size: $font-size-100;
    line-height: $line-height-100;

    @include mq(desktop-up) {
        position: static;
    }

    // LINK //

    &--selected {
        background: $color-accent-white;

        .#{$navigation-class-prefix}__meta-nav-link {
            &:link,
            &:visited {
                color: $color-base-grey-600;
            }
        }
    }
}

.#{$navigation-class-prefix}__meta-nav-link {
    position: relative;
    display: block;
    padding: $space-50 $space-100 $space-50 ($space-200 + $space-100);

    @include mq(desktop-up) {
        padding: $space-25 $space-100;
    }

    &:link,
    &:visited {
        color: $color-base-grey-600;

        @include mq(desktop-up) {
            color: $color-accent-white;
        }
    }
}
