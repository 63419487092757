.#{$navigation-class-prefix} {
    position: fixed;
    width: 100%;
    height: var(--navi-height);
    z-index: 22;
    top: 0;
    left: 0;

    .#{$navigation-class-prefix}__wrapper {
        position: fixed;
        width: 100%;
    }

    // Hover and focus styles for links & buttons

    a,
    button {
        &:hover {
            text-decoration: none;
        }

        &:focus {
            @include brix-focus;

            outline: none;
            text-decoration: none;
        }

        &:focus-visible {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }

        &:active {
            box-shadow: none;
        }
    }
}
