$core-styles-in-project: true;

@import '~@vfde-brix/styles/dist/scss/precss/precss';
@import '../../scss/contants';

$login-section-opener-class-prefix: '#{$navigation-class-prefix}-open-login-dropdown';
$login-section-class-prefix: '#{$navigation-class-prefix}-login-dropdown';

.#{$login-section-opener-class-prefix} {
    @include mq(desktop-up) {
        position: relative;
    }

    &__icon--close {
        display: none;
    }

    &__indicator {
        position: absolute;
        top: 7px;
        right: 5px;
        display: none;
        width: $size-icon-50;
        height: $size-icon-50;
        border-radius: 50%;
        background-color: $color-base-green-100;

        @include mq(tablet-s-up) {
            top: 12px;
            right: 13px;
        }

        @include mq(desktop-up) {
            right: 27px;
        }
    }

    &__indicator-icon {
        display: block;
        width: 100%;
        height: 100%;
        stroke: $color-accent-white;
        color: $color-accent-white;
    }

    &--logged-in {
        .#{$login-section-opener-class-prefix} {
            &__indicator {
                // important is used to override an inline-style
                // to avoid loading flicker in the local environment
                // stylelint-disable-next-line declaration-no-important
                display: block !important;
            }
        }
    }

    &--open {
        + .#{$login-section-class-prefix} {
            .#{$login-section-class-prefix}__inner {
                transform: translateY(0);
                visibility: visible;
            }
        }

        @media (hover: none) {
            .#{$login-section-opener-class-prefix} {
                &__icon--open {
                    display: none;
                }

                &__indicator {
                    // important is used to override an inline-style
                    // to avoid loading flicker in the local environment
                    // stylelint-disable-next-line declaration-no-important
                    display: none !important;
                }

                &__icon--close {
                    // important is used to override an inline-style
                    // to avoid loading flicker in the local environment
                    // stylelint-disable-next-line declaration-no-important
                    display: block !important;
                }
            }
        }
    }
}

.#{$login-section-class-prefix} {
    $login-dropdown-width: 288px;

    position: fixed;
    top: 100%;
    right: 0;

    // Explanation why z-index '-1' is needed:
    // it moves the dropdown behind the element '.mc-nav__icon-nav' (flex-item)
    z-index: -1;

    // 288px + 4px box shadow (left side)
    width: $login-dropdown-width + 4px;
    padding: 0 0 $space-25 $space-25;
    max-width: 100%;
    overflow: hidden;
    pointer-events: none;

    @include mq(desktop-up) {
        position: absolute;

        // Explanation why z-index '-2' is needed:
        // -1 moves the dropdown behind the element '.mc-nav__icon-nav' (flex-item)
        // -2 moves it behind the element '.mc-nav__main-nav-container::before'
        z-index: -2;
        max-width: none;

        // 288px + (2 * 4px box shadow) (left + right side)
        width: $login-dropdown-width + 8px;
        padding-right: 4px;
    }

    &__inner {
        display: flex;
        flex-direction: column;
        padding: $space-75 $space-100 $space-100;
        background-color: $color-accent-grey-25;
        box-shadow: $shadow-container;
        transition: transform $duration-200 $ease-in-sine, visibility $duration-200 $ease-in-sine;
        transform: translateY(calc(-100% - 4px));
        visibility: hidden;
        pointer-events: all;
    }

    &__text-container,
    &__button-container {
        display: flex;
        flex-direction: column;
    }

    &__text-container {
        margin-bottom: $space-75;
        font-size: $font-size-100;
        line-height: $line-height-100;

        p {
            margin-bottom: $space-50;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__last-login-value {
        display: flex;
        justify-content: space-between;
    }
}

.#{$navigation-class-prefix}__icon-nav-item:hover {
    .#{$login-section-opener-class-prefix} {
        &.#{$login-section-opener-class-prefix}--touch {
            .#{$navigation-class-prefix}__icon-nav-icon {
                stroke: $color-base-grey-600;
                color: $color-base-grey-600;
            }

            .#{$navigation-class-prefix}__icon-nav-label {
                color: $color-base-grey-600;
            }
        }
    }
}
