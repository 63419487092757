// LEVEL 1 -LIST
.#{$footer-class-prefix}__list--level-1 {
    position: relative;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    padding: $space-200 0 0;

    @include mq(desktop-up) {
        flex-direction: row;
        padding: ($space-200 + $space-25) 0 $space-100;
    }
}

.#{$footer-class-prefix}__item--level-1 {
    position: relative;
    width: 100%;
    border-bottom: $border-style-solid $border-width-s $color-accent-grey-300;
    font-size: $font-size-150;
    font-weight: $font-weight-normal;
    line-height: $size-icon-80;

    @include mq(desktop-up) {
        width: 25%;
        border-bottom: 0;
        font-size: $font-size-200;
        font-weight: $font-weight-bold;
        line-height: $line-height-50;
    }

    &:first-child {
        border-top: $border-style-solid $border-width-s $color-accent-grey-300;

        @include mq(desktop-up) {
            border-top: 0;
        }
    }
}

.#{$footer-class-prefix}__item.#{$footer-class-prefix}__item--level-1.#{$footer-class-prefix}__item--external {
    @include mq(desktop-up) {
        flex-basis: 25%;
        width: auto;
        margin-top: $space-25;
        margin-left: 75%;
        font-size: $font-size-100;
    }

    &:hover {
        .#{$footer-class-prefix}__link.#{$footer-class-prefix}__link--level-1.#{$footer-class-prefix}__link--external {
            color: $color-accent-grey-50;
            text-decoration: none;

            @include mq(desktop-up) {
                text-decoration: underline;
            }
        }

        .#{$footer-class-prefix}__external-icon {
            stroke: $color-accent-grey-50;
            color: $color-accent-grey-50;
        }
    }

    .#{$footer-class-prefix}__external-icon {
        position: relative;
        width: $size-icon-80;
        height: $size-icon-80;
        stroke: $color-accent-white;
        color: $color-accent-white;

        @include mq(desktop-down) {
            order: -1;
            margin-right: $space-25;
        }

        @include mq(desktop-up) {
            margin-left: $space-25;
        }
    }

    .#{$footer-class-prefix}__action-icon {
        transform: translate(0, -50%) scaleY(1) rotate(0deg);
    }
}

.#{$footer-class-prefix}__link.#{$footer-class-prefix}__link--level-1.#{$footer-class-prefix}__link--external {
    display: flex;
    align-items: center;
    width: 100%;
    color: $color-accent-white;
    padding: $space-150 $space-100;

    @include mq(desktop-up) {
        padding: $space-50 $space-100;
    }

    &:visited {
        color: $color-accent-white;
    }
}
