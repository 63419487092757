$core-styles-in-project: true;

@import '~@vfde-brix/styles/dist/scss/precss/precss';

$global-search-overlay-class-prefix: 'brix-gn-search-overlay';
$global-search-overlay-class-prefix-max-width: 616px;

.#{$global-search-overlay-class-prefix} {
    &__close-button {
        &.brix-button--circle-float {
            position: absolute;
        }
    }

    .brix-h1-style {
        text-align: center;
    }

    a {
        &:hover,
        &:focus {
            text-decoration: none;
        }

        &:focus {
            @include brix-focus;
        }

        &.#{$global-search-overlay-class-prefix}__results-item {
            &:active {
                box-shadow: none;
            }
        }
    }

    &__input-panel {
        position: relative;
        display: flex;
        align-content: center;
        max-width: $global-search-overlay-class-prefix-max-width;
        margin: 0 auto;
    }

    /* stylelint-disable-next-line selector-max-id */
    #mc-search-input-container {
        flex-grow: 1;
        align-self: center;
    }

    &__search-button {
        padding: 0 0 $space-25;
        margin-left: $space-75;

        @include mq(tablet-s-up) {
            margin-left: $space-100;
        }
    }

    &__results-wrapper {
        position: relative;
        max-width: $global-search-overlay-class-prefix-max-width;
        margin: 0 auto;
    }

    &__show-all {
        margin: $space-100 0;

        > .brix-button {
            @include mq(mobile-only) {
                width: 100%;
                justify-content: center;
            }
        }
    }

    &__notification {
        max-width: $global-search-overlay-class-prefix-max-width;
        margin: $space-100 auto 0;
    }
}
