.#{$footer-class-prefix} {
    position: relative;
    background-color: $color-accent-black;
    color: $color-accent-white;

    * {
        // Make sure every element inherits the white color
        // stylelint-disable-next-line declaration-no-important
        color: inherit !important;
    }

    a,
    button {
        &:focus {
            @include brix-focus;

            outline: none;
            text-decoration: none;
        }

        &:focus-visible {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }

    .brix-text-style {
        > :last-child {
            // remove bottom margin of last paragraphs etc.
            margin-bottom: 0;
        }
    }
}
