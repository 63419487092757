$global-navigation-meta-nav-height: $space-200;
$global-navigation-level-1-height: 78px;
$global-navigation-level-2-height: 54px;
$global-navigation-skip-to-content-height: 56px;
$global-navigation-deskop-mobile-menu-width: 308px;
$global-navigation-deskop-icon-breakpoint: 1432px;
$global-navigation-level-1-action-icon-wrapper-size: 57px;

@mixin brix-access {
    position: absolute;
    left: -$space-100;
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
}

@mixin logo-padding {
    @include mq(desktop-up) {
        padding-left: $space-300 + $space-200;
    }

    @media (min-width: $global-navigation-deskop-icon-breakpoint) {
        padding-left: 0;
    }
}
