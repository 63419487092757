.#{$footer-class-prefix}__action-icon {
    position: absolute;
    top: 50%;
    right: $space-100;
    display: block;
    width: $size-icon-80;
    height: $size-icon-80;
    transform: translate(0, -50%) scaleY(1) rotate(90deg);
    transition: transform $duration-500 $ease-in-out-sine;
    stroke: $color-accent-white;
    color: $color-accent-white;

    @include mq(desktop-up) {
        display: none;
    }
}
