.#{$navigation-class-prefix}__logo {
    position: absolute;
    top: $space-50;
    left: $space-100;
    z-index: 2;
    width: $size-button-small;
    height: $size-button-small;

    @include mq(tablet-s-up) {
        top: $space-75;
        width: $size-button-medium;
        height: $size-button-medium;
    }

    @include mq(desktop-up) {
        top: calc(50% + calc($global-navigation-meta-nav-height / 2));
        width: $size-button-large;
        height: $size-button-large;
        transform: translateY(-50%);
    }

    @media (min-width: $global-navigation-deskop-icon-breakpoint) {
        left: -($space-100 + $size-button-large);
    }

    @at-root {
        .#{$navigation-class-prefix} & {
            &:focus {
                @include brix-focus;
            }
        }
    }
}
