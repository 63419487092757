@import '~@vfde-brix/styles/dist/scss/precss/precss';
@import '../../scss/contants';

// MDD
@import './scss/mdd/mdd-settings';
@import './scss/mdd/mdd-animations';
@import './scss/mdd/mdd-toggle-button';
@import './scss/mdd/mdd-action-icon';
@import './scss/mdd/mdd-meta-nav';
@import './scss/mdd/mdd-logo';
@import './scss/mdd/mdd-skip-to-content';
@import './scss/mdd/mdd-icon-nav';
@import './scss/mdd/mdd-burger';
@import './scss/mdd/mdd-level-container-main-nav';
@import './scss/mdd/mdd-level';
@import './scss/mdd/mdd-level-1';
@import './scss/mdd/mdd-level-2';
@import './scss/mdd/mdd-level-3';
@import './scss/mdd/mdd-root';

// Breadcrumb
@import './scss/breadcrumb/breadcrumb';

// Sublevel
@import './scss/sublevel-navigation/sublevel-navigation';

// Footer
@import './scss/footer/footer-level-container-main-nav';
@import './scss/footer/footer-toggle-button';
@import './scss/footer/footer-action-icon';
@import './scss/footer/footer-media-badges';
@import './scss/footer/footer-level-1';
@import './scss/footer/footer-level-2';
@import './scss/footer/footer-socials';
@import './scss/footer/footer-legal';
@import './scss/footer/footer-root';

// HEADER HEIGHT - since navi and breadcrumb are positon fixed now, the content needs to be moved down

html {
    --navi-height: 48px;
    --breadcrumb-height: 45px;

    @include mq(tablet-s-up) {
        --navi-height: 64px;
    }

    @include mq(desktop-up) {
        --navi-height: 110px;
    }
}

.wrapper {
    &.noMDD {
        /*
        'noMDD' is an old class (thats why it is written in this strange camelcase style)
        which we continue to support with the new basis template.
        It basically hides the whole navigation content but not the contact section.
        If you also want to force hide the contact section, use the 'no-contact' class in addition.
        */
        .#{$breadcrumb-class-prefix} {
            display: none;
        }

        .#{$navigation-class-prefix}__list--level-1,
        .#{$navigation-class-prefix}__meta-nav-list--left,
        .#{$navigation-class-prefix}__meta-nav-list--right > .#{$navigation-class-prefix}__meta-nav-item:not(.#{$navigation-class-prefix}__meta-nav-item--contact-number),
        .#{$navigation-class-prefix}__burger {
            visibility: hidden;
        }

        .#{$navigation-class-prefix}__icon-nav {
            @include mq(desktop-down) {
                padding-right: 0;
            }
        }

        .#{$navigation-class-prefix}__icon-nav-item {
            @include mq(desktop-up) {
                // use visibility so the space does NOT collapse
                visibility: hidden;
            }
        }

        .#{$navigation-class-prefix}__icon-nav-item:not(.#{$navigation-class-prefix}__icon-nav-item--contact) {
            @include mq(desktop-down) {
                // use display so the space collapses
                display: none;
            }
        }
    }

    &.no-contact {
        /*
        'no-contact' is a new class which hides the 'contact-section' in desktop and mobile.
        It can be used in addition to 'noMDD' to hide the entire navigation content.
        */
        .#{$navigation-class-prefix}__meta-nav-list--right > .#{$navigation-class-prefix}__meta-nav-item--contact-number {
            // use display so the space collapses
            display: none;
        }

        .#{$navigation-class-prefix}__icon-nav-item--contact {
            @include mq(desktop-down) {
                // use visibility so the space does NOT collapse
                visibility: hidden;
            }
        }
    }
}

.#{$header-class-prefix} {
    height: var(--navi-height);

    .wrapper.with-breadcrumb:not(.noMDD) & {
        height: calc(var(--navi-height) + var(--breadcrumb-height));
    }
}
