// LEVEL-2-LIST
.#{$navigation-class-prefix}__list--level-2 {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    visibility: hidden;
    width: 100%;
    max-height: 0;
    background-color: $color-accent-grey-50;

    @include logo-padding;

    @include mq(desktop-down) {
        transition: max-height $duration-500 $ease-in-out-sine, visibility $duration-500 $ease-in-out-sine;
    }

    @include mq(desktop-up) {
        position: absolute;
        top: 100%;
        z-index: -3;
        flex-flow: row wrap;
        max-height: none;

        &:hover {
            z-index: -2;
        }
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $half-percent;
        z-index: -1;
        display: none;
        width: 100vw;
        height: 100%;
        background: $color-accent-grey-50;
        transform: translateX(-$half-percent);

        @include mq(desktop-up) {
            display: block;
        }
    }
}

// LEVEL-2-ITEM
.#{$navigation-class-prefix}__item--level-2 {
    font-size: $font-size-200;
    font-weight: $font-weight-normal;
    line-height: $line-height-100;

    @include mq(desktop-down) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        border-bottom: $border-width-s $border-style-solid $color-accent-white;
        background: $color-accent-grey-25;
    }

    &:last-child {
        @include mq(desktop-down) {
            border-bottom: 0;
        }
    }

    > .#{$navigation-class-prefix}__toggle-button {
        position: relative;
        min-height: $size-button-large;
        background: $color-accent-grey-25;
    }

    .#{$navigation-class-prefix}__link--level-2 {
        display: block;
        padding: $space-75 $space-100;

        &:hover,
        &:focus {
            color: $color-base-brand-100;

            @include mq(desktop-up) {
                background-color: $neutral-white;
            }
        }

        @include mq(desktop-down) {
            $width: calc(100% - #{$global-navigation-level-1-action-icon-wrapper-size});

            flex: 0 0 $width;
            max-width: $width;
        }

        @include mq(desktop-up) {
            padding: $space-100;
        }
    }

    [aria-expanded='true'] {
        @include mq(desktop-up) {
            background-color: $neutral-white;
        }

        // Make sure that level 3 can't be visible without level 2 being visible
        [aria-expanded="true"] ~ .#{$navigation-class-prefix}__list--level-2 & {
            ~ .#{$navigation-class-prefix}__list--level-3 {
                visibility: visible;
            }

            &.#{$navigation-class-prefix}__link--level-2 {
                @include mq(desktop-up) {
                    color: $color-base-brand-100;
                }
            }
        }

        ~ .#{$navigation-class-prefix}__list--level-3 {
            @include mq(desktop-down) {
                max-height: var(--mc-submenu-height-level-3, none);
            }
        }
    }

    &.#{$navigation-class-prefix}__item--no-children {
        .#{$navigation-class-prefix}__link--level-2 {
            @include mq(desktop-down) {
                flex-basis: 100%;
                max-width: none;
                padding-right: $global-navigation-level-1-action-icon-wrapper-size + $space-100;
                margin-right: 0;
            }
        }
    }

    &.#{$navigation-class-prefix}__item--selected {
        .#{$navigation-class-prefix}__link--level-2 {
            color: $color-base-brand-100;
            font-weight: $font-weight-bold;
        }
    }
}
