// LEVEL-3-LIST
.#{$navigation-class-prefix}__list--level-3 {
    position: relative;
    left: 0;
    top: 0;
    display: flex;
    flex-flow: column nowrap;
    visibility: hidden;
    width: 100%;
    max-height: 0;
    background-color: $color-accent-white;

    @include logo-padding;

    // BURGER VIEWPORT
    @include mq(desktop-down) {
        transition: max-height $duration-500 $ease-in-out-sine, visibility $duration-500 $ease-in-out-sine;
    }

    @include mq(desktop-up) {
        position: absolute;
        top: 100%;
        z-index: -3;
        flex-flow: row wrap;
        max-height: none;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $half-percent;
        z-index: -1;
        display: none;
        width: 100vw;
        height: 100%;
        background: $color-accent-white;
        box-shadow: 0 2px 4px 0 rgba(51, 51, 51, .15);
        transform: translateX(-$half-percent);

        @include mq(desktop-up) {
            display: block;
        }
    }
}

// LEVEL-3-ITEM
.#{$navigation-class-prefix}__item--level-3 {
    width: auto;
    height: auto;
    border-bottom: $border-width-s $border-style-solid $color-accent-grey-25;
    font-size: $font-size-150;
    line-height: $line-height-100;

    &:last-child {
        border-bottom: 0;
    }

    @include mq(desktop-down) {
        position: relative;
    }

    @include mq(desktop-up) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 25%;
        height: 44px + $space-75 * 2;
        padding: $space-75 0 $space-75 $space-100;
        border-bottom: 0;
        line-height: $line-height-50;

        &:nth-child(n+5) {
            margin-top: -$space-150;
        }
    }

    .#{$navigation-class-prefix}__link--level-3 {
        $padding-right: $global-navigation-level-1-action-icon-wrapper-size + $space-100;

        display: block;
        padding: $space-100 $padding-right $space-100 $space-100;

        @include mq(desktop-up) {
            align-self: flex-start;
            padding: 0;
        }

        &:hover,
        &:focus {
            color: $color-base-brand-100;
        }

        &:hover {
            @include mq(desktop-up) {
                text-decoration: underline;
            }
        }
    }

    &.#{$navigation-class-prefix}__item--selected {
        .#{$navigation-class-prefix}__link--level-3 {
            color: $color-base-brand-100;
        }
    }
}
