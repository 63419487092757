.#{$footer-class-prefix}__main-nav {
    position: relative;
    max-width: $max-page-width;
    margin: 0 auto $space-200;

    &::after {
        content: '';
        display: none;
        height: $border-width-s;
        margin: 0 $space-100;
        background: $color-accent-grey-300;

        @include mq(desktop-up) {
            display: block;
        }
    }
}
