// container
.#{$navigation-class-prefix}__main-nav {
    position: relative;
    max-width: $max-page-width;
    margin: 0 auto;
    padding-top: 0;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    backface-visibility: hidden;
    transform: translateZ(0) scale(1, 1);

    @include mq(desktop-up) {
        flex-direction: row;
        padding-top: $global-navigation-meta-nav-height;
        background-color: $color-accent-white;
    }
}

.#{$navigation-class-prefix}__main-nav-container {
    $top: $space-75 + $space-150 + $space-75;

    position: absolute;
    top: $top;
    right: -1px; // -1px is needed to hide the border when navi inside is closed (display: none = width: 0px) (see INC-38336)
    height: calc(100vh - #{$top});
    height: calc(100dvh - #{$top});
    border-left: $border-style-solid $border-width-s $color-accent-grey-25;
    background-color: $color-accent-white;
    overflow-y: auto;

    @include mq(tablet-s-up) {
        top: $space-400;
        height: calc(100vh - #{$space-400});
    }

    @include mq(desktop-up) {
        position: static;
        height: auto;
        border-left: 0;
        overflow-y: initial;
    }

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: $half-percent;
        z-index: -1;
        display: none;
        width: 100vw;
        height: 100%;
        background: $color-accent-white;
        box-shadow: $shadow-container;
        transform: translateX(-$half-percent);

        @include mq(desktop-up) {
            display: block;
        }
    }
}
