.#{$navigation-class-prefix}__toggle-button {
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: $global-navigation-level-1-action-icon-wrapper-size;
    cursor: pointer;

    @include mq(desktop-up) {
        display: none;
    }

    &:focus {
        @include brix-focus;

        outline: none;
    }

    &:focus-visible {
        outline: none;

        .#{$navigation-class-prefix}__action-icon {
            stroke: $color-base-brand-100;
            color: $color-base-brand-100;
        }
    }

    &:focus:not(:focus-visible) {
        box-shadow: none;
    }

    &[aria-expanded='true'] {
        .#{$navigation-class-prefix}__action-icon {
            stroke: $color-base-brand-100;
            color: $color-base-brand-100;
            transform: translate(-50%, -50%) scaleY(-1) rotate(90deg);
        }
    }
}
