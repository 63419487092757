// show-animation
@keyframes mc-nav-list-out {
    0% {
        opacity: 0;
        transform: translateY(-100%);
    }

    80% {
        opacity: 1;
    }

    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

// hide-animation
@keyframes mc-nav-list-in {
    0% {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }

    20% {
        opacity: 1;
    }

    100% {
        opacity: 0;
        transform: translateY(-100%);
    }
}

@include mq(desktop-up) {
    .#{$navigation-class-prefix}__list--animation-out,
    .#{$navigation-class-prefix}__list--animation-in {
        animation-timing-function: $ease-in-out-sine;
    }

    .#{$navigation-class-prefix}__list--animation-out {
        // show-animation
        animation-name: mc-nav-list-out;
        animation-duration: $duration-200;
    }

    .#{$navigation-class-prefix}__list--animation-in {
        // hide-animation
        animation-name: mc-nav-list-in;
        pointer-events: none;
    }

    .#{$navigation-class-prefix}__list--level-2 {
        &.#{$navigation-class-prefix}__list--animation-in {
            // hide-animation
            animation-duration: $duration-500;
        }
    }

    .#{$navigation-class-prefix}__list--level-3 {
        &.#{$navigation-class-prefix}__list--animation-in {
            // hide-animation
            animation-duration: $duration-500;
        }
    }
}
