// burger label
.#{$navigation-class-prefix}__burger {
    // position: absolute;
    // top: -$space-400;
    position: fixed;
    top: 0;
    right: $space-25;
    display: block;
    padding: $space-75;
    cursor: pointer;
    background-color: $color-accent-white;
    z-index: 2;

    @include mq(tablet-s-up) {
        right: 0;
        padding: $space-100;
    }

    @include mq(desktop-up) {
        display: none;
    }
}

.#{$navigation-class-prefix}__burger--active {
    &::before {
        position: absolute;
        display: block;
        content: '';
        top: $space-25;
        left: $space-25;
        width: $size-button-medium;
        height: $size-button-medium;
        background: $color-base-brand-100;

        @include mq(tablet-s-up) {
            top: $space-75;
            left: $space-75;
        }
    }

    .#{$navigation-class-prefix}__burger-icon {
        stroke: $color-accent-white;
        color: $color-accent-white;

        &--open {
            display: none;
        }

        &--close {
            display: block;
        }
    }

    ~ .#{$navigation-class-prefix} {
        &__list--level-1,
        &__meta-nav {
            @include mq(desktop-down) {
                display: flex;
            }
        }

        &__list--level-1::after {
            @include mq(desktop-down) {
                display: block;
            }
        }
    }
}

.#{$navigation-class-prefix}__burger-icon {
    position: relative;
    width: $size-icon-75;
    height: $size-icon-75;
    stroke: $color-base-grey-600;
    color: $color-base-grey-600;

    @include mq(tablet-s-up) {
        width: $size-icon-80;
        height: $size-icon-80;
    }

    &--open {
        display: block;
    }

    &--close {
        display: none;
    }
}
