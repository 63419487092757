// LEVEL-1-LIST
.#{$navigation-class-prefix}__list--level-1 {
    display: none;
    flex-direction: column;
    background: $color-accent-white;

    @include logo-padding;

    @include mq(desktop-down) {
        position: relative;
        right: 1px; // To compensate for the right: -1px in parent element (see INC-38336)
        width: $global-navigation-deskop-mobile-menu-width;
    }

    @include mq(desktop-up) {
        display: flex;
        flex-direction: row;
    }

    &::after {
        content: '';
        position: fixed;
        top: 0;
        right: 0;
        z-index: -1;
        display: none;
        width: 100vw;
        height: 100vh;
        background: $color-base-grey-600;
        opacity: $opacity-60;
    }
}

// LEVEL-1-ITEM
.#{$navigation-class-prefix}__item--level-1 {
    list-style: none;
    font-size: $font-size-300;
    font-weight: $font-weight-normal;
    line-height: $line-height-50;
    margin: 0;

    @include mq(desktop-down) {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        border-bottom: $border-width-s $border-style-solid $color-accent-grey-25;
        background: $neutral-white;
    }

    @include mq(desktop-up) {
        font-weight: $font-weight-light;
    }

    &:last-child {
        @include mq(desktop-down) {
            border-bottom: 0;
        }
    }

    > .#{$navigation-class-prefix}__toggle-button {
        position: relative;
        min-height: $global-navigation-level-1-action-icon-wrapper-size;
        background-color: $color-accent-grey-50;
    }

    .#{$navigation-class-prefix}__link--level-1 {
        display: block;
        padding: $space-100;

        @include mq(desktop-down) {
            $width: calc(100% - #{$global-navigation-level-1-action-icon-wrapper-size});

            flex: 0 0 $width;
            max-width: $width;
        }

        @include mq(desktop-up) {
            padding: $space-150 $space-100;
            white-space: nowrap;
        }

        &:hover,
        &:focus {
            color: $color-base-brand-100;

            ~ .#{$navigation-class-prefix}__list--level-2 {
                @include mq(desktop-up) {
                    z-index: -2;
                }
            }
        }

        &[aria-expanded='true'] {
            @include mq(desktop-up) {
                color: $color-base-brand-100;
            }
        }
    }

    [aria-expanded='true'] {
        ~ .#{$navigation-class-prefix}__list--level-2 {
            visibility: visible;

            @include mq(desktop-down) {
                max-height: var(--mc-submenu-height-level-2, none);
            }
        }
    }

    &.#{$navigation-class-prefix}__item--no-children {
        .#{$navigation-class-prefix}__link--level-1 {
            @include mq(desktop-down) {
                margin-right: 0;
            }
        }
    }

    &.#{$navigation-class-prefix}__item--level-1.#{$navigation-class-prefix}__item--selected {
        @include mq(desktop-up) {
            box-shadow: 0 (-$border-width-l) $color-base-brand-100 inset;
        }

        .#{$navigation-class-prefix}__link--level-1 {
            @include mq(desktop-up) {
                color: $color-base-brand-100;
            }
        }
    }

    &.#{$navigation-class-prefix}__item--selected {
        box-shadow: -57px 0 $color-accent-white inset, -60px 0 $color-base-brand-100 inset;

        @include mq(desktop-up) {
            box-shadow: 0 (-$border-width-l) $color-base-brand-100 inset;
        }

        .#{$navigation-class-prefix}__link--level-1 {
            color: $color-base-brand-100;
        }
    }
}
