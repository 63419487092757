.#{$navigation-class-prefix}__skip-to-content {
    display: flex;
    justify-content: center;
    background-color: $color-base-brand-100;

    a {
        position: fixed;
        top: -100px;
        padding: $space-50 $space-75;
        margin: $space-50 0;
        font-weight: $font-weight-bold;
        font-size: $font-size-100;
        line-height: $line-height-100;
        text-align: center;

        /* stylelint-disable-next-line declaration-no-important */
        text-decoration: underline !important;
        color: $neutral-white;

        &:focus {
            position: relative;
            top: 0;
        }

        &:visited {
            color: $neutral-white;
        }
    }
}
