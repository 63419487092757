.#{$breadcrumb-class-prefix} {
    position: fixed;
    top: var(--navi-height);
    z-index: 21;
    width: 100%;
    height: var(--breadcrumb-height);
    background-color: $color-accent-white;
    transition: transform .4s ease-out;
    border-bottom: $border-width-s solid $color-accent-grey-50;

    &--hidden {
        transform: translateY(-100%);
    }

    &__wrapper {
        position: relative;
        max-width: $max-page-width;
        margin: 0 auto;
    }

    &__list-wrapper {
        position: relative;
        overflow-x: hidden;
    }

    &__list {
        position: relative;
        display: flex;
        width: max-content;
        padding: 0 0 0 $space-50;
        transition: all .3s ease-in-out;
    }

    &__list-item {
        position: relative;
        font-weight: $font-weight-normal;
        font-size: $font-size-50;
        line-height: $line-height-100;
        padding-right: $font-size-50;

        &:last-child {
            padding-right: $space-50;

            .#{$breadcrumb-class-prefix} {
                &__list-item-text {
                    font-weight: $font-weight-bold;
                    color: $color-base-brand-100;
                }
            }
        }

        &:not(:last-child)::after {
            position: absolute;
            right: 0;
            top: $space-100;
            content: '';
            width: $font-size-50;
            height: $font-size-50;
            transform: rotate(270deg);

            @include get-icon(chevron-dark);
        }
    }

    &__list-item-link,
    &__list-item-text {
        display: block;
        padding: $space-75 $space-50;

        &:hover {
            color: $color-base-brand-100;
        }

        &:focus {
            color: $color-base-brand-100;
        }
    }

    a {
        &:focus {
            @include brix-focus;
        }

        &:focus-visible {
            outline: none;
        }

        &:focus:not(:focus-visible) {
            box-shadow: none;
        }
    }

    // next and previous
    &__reveal-icon {
        position: relative;
        width: $size-icon-75;
        height: $size-icon-75;
        stroke: $color-base-grey-600;
        color: $color-base-grey-600;
        background: $color-accent-white;
        cursor: pointer;
    }

    &__prev,
    &__next {
        position: absolute;
        align-items: center;
        top: 0;
        left: 0;
        height: 100%;
        display: none;
        border-color: $color-accent-white;
        border-style: solid;

        @include mq(desktop-down) {
            /* stylelint-disable-next-line declaration-no-important */
            display: none !important;
        }

        &::after,
        &::before {
            content: '';
            position: relative;
            width: $space-100;
            height: 100%;

            @include mq(desktop-up) {
                width: $space-200;
            }
        }
    }

    &__next {
        left: auto;
        right: 0;

        @include mq(desktop-up) {
            border-right-width: $space-100;
        }

        &::before {
            background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
        }

        &::after {
            content: none;
        }
    }

    &__prev {
        @include mq(desktop-up) {
            border-left-width: $space-100;
        }

        &::before {
            content: none;
        }

        &::after {
            background: linear-gradient(90deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 0) 100%);
        }
    }
}
