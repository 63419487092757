.#{$footer-class-prefix}__media-badges-link-wrapper {
    display: flex;
    justify-content: center;
}

.#{$footer-class-prefix}__media-badges-link {
    @include h5-style(no-spacing);

    position: relative;
    color: $color-accent-white;
    padding: $space-50 $space-100;
    margin-bottom: $space-150;

    &:visited {
        color: $color-accent-white;
    }

    &:hover {
        color: $color-accent-grey-50;

        .#{$footer-class-prefix}__media-badges-icon {
            stroke: $color-accent-grey-50;
            color: $color-accent-grey-50;
        }
    }
}

.#{$footer-class-prefix}__media-badges-link-icon {
    width: $size-icon-80;
    height: $size-icon-80;
    stroke: $color-accent-white;
    color: $color-accent-white;
    margin-left: $space-25;
}

// Badges-List
.#{$footer-class-prefix}__media-badges-list {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: calc($space-300 - $space-75 / 2);
}

.#{$footer-class-prefix}__media-badges-item {
    position: relative;
    padding: 0 $space-100;

    @include mq(tablet-m-down) {
        &:nth-child(n+5) {
            display: none;
        }
    }

    @include mq(mobile-only) {
        &:nth-child(n+3) {
            display: none;
        }
    }
}

.#{$footer-class-prefix}__media-badges-img {
    position: relative;
    display: block;
}
